import React from 'react'
import Form from '../components/form'
import BlogSlider from '../components/blog-slider';
import { Helmet } from 'react-helmet';



export default function Contact() {
  return (
   <>
                                               <Helmet>
        <title>Contact Fornax Crypto Marketing Agency</title>
        <meta name="keywords" content="About Fornax Agency" />

      </Helmet>
               <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Contact Us</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>Contact</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section id='contact' className='contact-page-section my-5 py-5'>
                <div className='container'>
                    <div className='row'>
   
                        <div className='col-lg-6 pe-lg-4'>
        <div className='content-style-01'>
    <h5>Creative Agency</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        
        <div className='col-lg-6 ps-lg-4'>
                        <div className='content-style-01'>
    <h5>Quick Contact</h5>
    <h2>Share Your Vision</h2>
    </div>
<p>
    Get in touch with us quickly by filling out the form. Share key details about your project or brand, and we’ll get back to you within 2 business days.
    </p>
    <p>
Stay connected and don’t forget to follow us on social media for the latest updates and insights.

</p>
<p>
Our team is here to support your vision and help bring your ideas to life. Whether you need strategic guidance or full-scale marketing solutions, we’re ready to collaborate with you.
</p>
<p>

Connect, share, and watch your brand grow with our expert assistance. We look forward to hearing from you and working together on your next big project.
</p>                     </div>
                    </div>
                </div>
            </section>

            <BlogSlider></BlogSlider>
   </>
  )
}
