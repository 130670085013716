import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';



function MarketingStrategy(props) {
    return (
        <>
              <Helmet>
        <title>Marketing Strategy</title>
        <meta name="keywords" content="marketing strategy" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Marketing Strategy</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Marketing Strategy</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Skilled Experts</h5>
                    <h2>What is a Marketing Strategy?
                    </h2>
                    <p>
                    A marketing strategy refers to a marketing plan that is suitable and feasible for your goal. At its core, it consists of timing, platform (location), target audience, and budget plans. Every relevant variable needs to be planned as a whole and in harmony to reach your goal. If you don't have a marketing strategy, your promotional efforts will fall short of expectations.
                    </p>
                    <p>
At Fornax, we create detailed marketing strategies for our clients who are open to long-term collaboration. If conditions change over time, we update the strategy and optimize the goals. Particularly, the cryptocurrency/blockchain ecosystem has a frequently changing structure. Unlike industrial marketing, market fluctuations and community movements need to be closely monitored, and strategies should be revised if necessary.
</p>
<p>
Below are the report titles we create within the scope of the marketing strategy. For each title, we conduct analyses suitable for the dynamics of your project and brand, and create a marketing plan. We offer 3 main headings for the marketing strategy: Stage 1 analysis, Stage 2 tasks, and Stage 3 roadmap.
</p>



<div className='row mt-5'>
<div className='col-6'>
                <ul className="check-list mb-4">
                   <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Goals</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Audience Analysis</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Region Analysis</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Keywords Planning
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Keyword Volume Analysis</p>
                            </div>
                        </li>
                    </ul>

                    </div>
                    <div className='col-6'>
                <ul className="check-list mb-4">

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Ads Schedule & Budget</p>
                            </div>
                        </li>

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Ad / Marketing Materials</p>
                            </div>
                        </li>

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Funnel & Landing Page for Ads</p>
                            </div>
                        </li>

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Audience Directing</p>
                            </div>
                        </li>

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Creating Expectations</p>
                            </div>
                        </li>
                    </ul>

                    </div>
                    </div>
                </div>

                {/* <div className='col-lg-4 d-lg-block d-none'>
                <img src={marketingbanner1} className='img-fluid radius' title='Marketing Strategy' alt='Marketing Strategy'></img>
                </div> */}


                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>

<ContactSection></ContactSection>
        </>
    )
}

export default MarketingStrategy
