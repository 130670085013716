import React from 'react'
import Form from '../components/form'
import ContactSection from '../components/contact-section';
import BlogSlider from '../components/blog-slider';
import { Helmet } from 'react-helmet';


import banner2 from '../assets/banner-2.webp';

function About(props) {
    return (
        <>
                                            <Helmet>
        <title>About Fornax Crypto Marketing</title>
        <meta name="keywords" content="About Fornax Agency" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>About Us</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>About Us</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-6'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-7'>
                    <h5>Experienced Team</h5>
                    <h2>Who We Are?</h2>
<p>
                    Fornax Marketing Agency is comprised of experienced employees specialized in various fields. Marketing is a multifaceted and multilayered service. In this context, our team consists of marketing experts, developers, and designers.
                    </p>
                    <p>           
Since 2012, we have been providing marketing and web development services for e-commerce and crypto projects. As part of our marketing strategy, we analyze the target audience, statistics, and competitive conditions, and then create the digital materials you need.
</p>
<p>
Marketing consists of goals at different levels. We provide long-term services in brand awareness, generating conversions, and remarketing. Our team consists of employees with expertise in the following areas:







</p>
<div className='row mt-5'>
<div className='col-6'>
                <ul className="check-list mb-4">
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Marketing Strategist</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Backend Developer</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Front End Developer</p>
                            </div>
                        </li>
                    </ul>

                    </div>
                    <div className='col-6'>
                <ul className="check-list mb-4">
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>UI Designer
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Video Editor</p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Social Media Expert</p>
                            </div>
                        </li>
                    </ul>

                    </div>
                    </div>
                </div>
                <div className='col-lg-5'>
                <img className='img-fluid radius' src={banner2} title='Banner1' alt='Banner1'></img>
</div>

                </div>

                </div>


            </section>

            <section className='form-section mb-5 pb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>

<BlogSlider></BlogSlider>
<ContactSection></ContactSection>

        </>
    )
}

export default About
