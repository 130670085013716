import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function GoogleAds(props) {
    return (
        
        <>
                              <Helmet>
        <title>Google Ads Campaigns - Fornax Marketing</title>
        <meta name="keywords" content="Google Ads Campaigns" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Google Ads</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Google Ads</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Ad Gurus</h5>
                    <h2>Why Google Ads?
                    </h2>
                    <p>
                    Alongside influencer promotions, one of the most effective advertising methods is Google Ads. YouTube ads, in particular, provide significant reach to your target audience. You can define the specific characteristics of your target audience using Google Ads tools and publish your promotional video on selected videos or YouTube channels.
</p>
<p>
Another advantage of this advertising method is the "conversion" capability. You have the opportunity to reach out later to those who have viewed your promotional video and clicked on your ad. Google Ads campaigns allow you to reach a large number of people with a small budget.
</p>
<p>
At Fornax, we identify your target audience for YouTube ads, create the promotional video, and set up a "funnel" structure for your website. We determine the most appropriate categories, channels, or videos to reach your target audience and create an effective awareness campaign. We regularly monitor your campaigns and report on their effectiveness with statistics.
</p>





                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default GoogleAds
