import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function SeoSem(props) {
    return (
        <>
            <Helmet>
        <title>SEO & SEM - Fornax Crypto Marketing</title>
        <meta name="keywords" content="SEO & SEM" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>SEO & SEM
                    </h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>SEO & SEM
                            </li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 content-style-03 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>SEO Specialists</h5>
                    <h2>SEO & SEM Services

                    </h2>
                    <p>At <strong>Fornax</strong>, we offer comprehensive SEO (Search Engine Optimization) and SEM (Search Engine Marketing) services designed to enhance your online visibility and drive targeted traffic to your crypto project. Our strategies are tailored to meet your specific goals and maximize your digital presence. Our SEO & SEM Services Include:</p>

<ul>
    <li><strong>Keyword Research:</strong> We conduct in-depth keyword research to identify the most relevant and high-performing keywords for your crypto project, ensuring your content reaches the right audience.</li>
    <li><strong>On-Page SEO:</strong> Our team optimizes your website’s on-page elements, including meta tags, headings, and content, to improve search engine rankings and enhance user experience.</li>
    <li><strong>Technical SEO:</strong> We address technical aspects of your website, such as site speed, mobile-friendliness, and crawlability, to ensure optimal performance and indexing by search engines.</li>
    <li><strong>Link Building:</strong> We develop a strategic link-building plan to acquire high-quality backlinks that boost your website’s authority and search engine ranking.</li>
    <li><strong>Content Marketing:</strong> Our content marketing strategies focus on creating valuable and engaging content that attracts and retains your target audience, driving organic traffic to your site.</li>
    <li><strong>SEM Campaigns:</strong> We manage and optimize your SEM campaigns, including Google Ads and other paid search platforms, to increase visibility, drive qualified leads, and achieve a high return on investment.</li>
    <li><strong>Analytics and Reporting:</strong> We provide detailed analytics and reports on your SEO and SEM performance, offering insights and recommendations to continuously improve your strategies.</li>
</ul>

<h3>Why Choose Fornax for Your SEO & SEM Needs?</h3>
<ul>
    <li><strong>Expertise in Crypto Projects:</strong> We understand the unique challenges and opportunities of the cryptocurrency industry and tailor our SEO and SEM strategies accordingly.</li>
    <li><strong>Data-Driven Approach:</strong> Our strategies are based on thorough analysis and data-driven insights to ensure effective and measurable results.</li>
    <li><strong>Commitment to Results:</strong> We are dedicated to achieving your marketing goals and delivering tangible improvements in your online visibility and performance.</li>
</ul>

<p>At <strong>Fornax</strong>, we are committed to helping your crypto project succeed in the digital landscape. Let us enhance your online presence with our expert SEO and SEM services. Contact us today to start optimizing your digital strategy.</p>


                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>

<ContactSection></ContactSection>
        </>
    )
}

export default SeoSem
