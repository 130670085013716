import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function WebDevelopment(props) {
    return (
        <>
                            <Helmet>
        <title>Web Development - Fornax Crypto Marketing</title>
        <meta name="keywords" content="Web Development" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Web Development
                    </h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Web Development
                            </li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 content-style-03 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Experienced Devs</h5>
                    <h2>Web Development Services

                    </h2>
    <p>At <strong>Fornax</strong>, we specialize in creating cutting-edge websites and web solutions tailored to the needs of crypto projects and businesses. Our team of experienced developers is dedicated to delivering high-quality, secure, and scalable web solutions that drive results. Our Web Development Services Include: </p>

    
    <ul>
        <li><strong>Custom Website Design:</strong> We build visually stunning and user-friendly websites that capture the essence of your crypto project and engage your audience effectively.</li>
        <li><strong>Web Application Development:</strong> Whether you need a sophisticated trading platform, a secure wallet system, or a blockchain-based application, we have the expertise to develop robust and efficient web applications.</li>
        <li><strong>Responsive Design:</strong> Our websites are designed to be fully responsive, ensuring a seamless user experience across all devices and screen sizes.</li>
        <li><strong>E-Commerce Solutions:</strong> For crypto projects requiring an online marketplace or payment gateway integration, we provide comprehensive e-commerce solutions that facilitate smooth transactions and enhance user experience.</li>
        <li><strong>SEO Optimization:</strong> We optimize your website to rank higher in search engine results, increasing visibility and driving targeted traffic to your crypto project.</li>
        <li><strong>Maintenance and Support:</strong> Our commitment doesn’t end with the launch. We offer ongoing maintenance and support to ensure your website remains up-to-date and performs at its best.</li>
    </ul>

    <h3>Why Choose Fornax for Your Web Development Needs?</h3>
    <ul>
        <li><strong>Expertise in Crypto Projects:</strong> We understand the unique requirements of the cryptocurrency industry and offer tailored solutions that meet your specific needs.</li>
        <li><strong>InFornaxtive Solutions:</strong> Our team stays ahead of industry trends to provide inFornaxtive web solutions that set you apart from the competition.</li>
        <li><strong>Commitment to Quality:</strong> We deliver high-quality work with a focus on performance, security, and scalability.</li>
    </ul>

    <p>At <strong>Fornax</strong>, we are passionate about helping crypto projects succeed online. Let us transform your vision into a powerful web presence that drives growth and engagement. Contact us today to discuss your web development needs.</p>


                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default WebDevelopment
